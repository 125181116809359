<template>
  <div>
    <ServerError v-if="ServerError" /> <PageLoader :storage="appLoading" />
    <v-layout wrap justify-center>
      <v-flex
        align-self-center
        xs12
        v-if="!$route.query.id || $route.query.id == 'activeCases'"
      >
        <Cases
          v-if="activeCases.length > 0"
          :storage="activeCases"
          :pageType="'activeCases'"
        />
        <v-layout
          justify-center
          style="height: 80vh"
          v-if="activeCases && activeCases.length == 0"
          wrap
        >
          <v-flex align-self-center xs12 text-center>
            <!-- <v-icon :color="appColor">mdi-file-find-outline</v-icon><br> -->

            <span style="font-family: sofiaProBold; font-size: 18px">
              Case List is Empty</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 v-else-if="$route.query.id == 'assignedCases'">
        <AssignedCases v-if="assignedCases.length > 0" :storage="assignedCases" />
      </v-flex> -->
      <v-flex
        align-self-center
        xs12
        v-else-if="$route.query.id == 'assignedCases'"
      >
        <Cases
          v-if="assignedCases.length > 0"
          :storage="assignedCases"
          :pageType="'assignedCases'"
        />
        <v-layout
          justify-center
          style="height: 80vh"
          v-if="assignedCases &&assignedCases.length == 0"
          wrap
        >
          <v-flex align-self-center xs12 text-center>
            <!-- <v-icon :color="appColor">mdi-file-find-outline</v-icon><br> -->

            <span style="font-family: sofiaProBold; font-size: 18px">
              Case List is Empty</span
            >
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 v-else-if="$route.query.id == 'releasedCases'">
        <ReleaseCases
          v-if="releasedCases.length > 0"
          :storage="releasedCases"
          :pageType="'releasedCases'"
        />
        <v-layout
          justify-center
          style="height: 80vh"
          v-if="releasedCases && releasedCases.length == 0"
          wrap
        >
          <v-flex align-self-center xs12 text-center>
            <!-- <v-icon :color="appColor">mdi-file-find-outline</v-icon><br> -->

            <span style="font-family: sofiaProBold; font-size: 18px">
              Case List is Empty</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 v-else-if="$route.query.id == 'resolvedCases'">
        <ResolveCases
          v-if="resolvedCases.length > 0"
          :storage="resolvedCases"
          :pageType="'resolvedCases'"
        />
        <v-layout
          justify-center
          style="height: 80vh"
          v-if="resolvedCases && resolvedCases.length == 0"
          wrap
        >
          <v-flex align-self-center xs12 text-center>
            <!-- <v-icon :color="appColor">mdi-file-find-outline</v-icon><br> -->

            <span style="font-family: sofiaProBold; font-size: 18px">
              Case List is Empty</span
            >
          </v-flex>
        </v-layout>
      </v-flex>
      <!-- <v-flex xs12 v-else-if="$route.query.id == 'mergeCases'">
        <MergeCases />
      </v-flex> -->
    </v-layout>

    <v-layout wrap py-4 justify-end>
      <v-flex xs12 sm1 align-self-center>
        <v-select
        v-model="count"
         color="#FF6907"
        class="rounded-xl"
        dense
        hide-details
        label="Rows per page"
        :items="[20,40,80]"
        ></v-select>
      </v-flex>
      <v-flex xs12 sm11 align-self-center>
        <v-pagination  small circle color="#FF6907" v-model="page" :length="Pagelength">
        </v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import Cases from "@/views/Admin/Cases/cases";
import ResolveCases from "@/views/ResolvedCasesList/resolveList"
import ReleaseCases from "@/views/ReleasedCasesList/releaseList"

// import MergeCases from "@/views/Officers/MergeCases/mergeCases";
export default {
  components: {
    Cases,
    ResolveCases,
    ReleaseCases
    // MergeCases,
  },
  data() {
    return {
      appLoading: false,
      caseDetails: [],
      activeCases: [],
      assignedCases: [],
      resolvedCases: [],
      releasedCases:[],
      ServerError: false,
      capturedCases: [],
      searchKey: null,
      currentPage: 1,
      page: 1,
      Pagelength: 0,
      count:20,
      intervalId: null,
    };
  },
  beforeMount() {
    this.searchKey = this.$route.query.searchKey;
    // if (this.searchKey) this.getSearch();
    // else this.getData();
    // this.getresolveData();
    if (this.searchKey) {
    this.getSearch();
    }
    else {
      this.getData();
      this.getresolveData();
    }
  //   setInterval(() => {
  //   this.getData();
  //   this.getresolveData();
  //   window.location.reload();
  // }, 10000);
  },
  // mounted() {
  //   this.getData();
  //   this.getSearch()

  // },
  watch: {
    page() {
      //this.getData();
      this.getresolveData();
    },
    count() {
      this.page=1
      this.getData();
      this.getresolveData();
    }
  },
  methods: {
    // getData() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/reports/all/list",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: {
    //       page: this.page,
    //       limit: this.count,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.caseDetails = response.data;
    //         this.activeCases = this.caseDetails.activeCases;
    //         this.assignedCases = this.caseDetails.assignedCases;
    //         this.releasedCases = this.caseDetails.releasedCases;
    //         // this.resolvedCases = this.caseDetails.resolvedCases;
    //         if (this.$route.query.id == "activeCases") {
    //           this.Pagelength = Math.ceil(response.data.totalActiveLength / this.count);
    //         } else if (this.$route.query.id == "assignedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalAssignedLength / this.count);
    //         } else if (this.$route.query.id == "releasedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalReleasedLength / this.count);
    //         } else if (this.$route.query.id == "resolvedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalResolvedLength / this.count);
    //         }  
    //         else {
    //           this.Pagelength = Math.ceil(response.data.totalActiveLength / this.count);
    //         }
    //         this.appLoading = false;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    // getresolveData() {
    //   this.appLoading = true;
    //   axios({
    //     method: "POST",
    //     url: "/resolve/list",
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //     data: {
    //       page: this.page,
    //       limit: this.count,
    //     },
    //   })
    //     .then((response) => {
    //       if (response.data.status) {
    //         this.resolvedCases = response.data.data;
    //         if (this.$route.query.id == "activeCases") {
    //           this.Pagelength = Math.ceil(response.data.totalActiveLength / this.count);
    //         } else if (this.$route.query.id == "assignedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalAssignedLength / this.count);
    //         } else if (this.$route.query.id == "releasedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalReleasedLength / this.count);
    //         } else if (this.$route.query.id == "resolvedCases") {
    //           this.Pagelength = Math.ceil(response.data.totalResolvedLength / this.count);
    //         }  
    //         else {
              
    //           this.Pagelength = Math.ceil(response.data.totalActiveLength / this.count);
    //         }
    //         this.appLoading = false;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       console.log(err);
    //     });
    // },
    getSearch() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/report/search",
        data: {
          keyword: this.searchKey,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.caseDetails = response.data;
            this.$store.commit("counterData", response.data.count);
            this.activeCases = this.caseDetails.activeCases;
            this.assignedCases = this.caseDetails.assignedCases;
            this.capturedCases = this.caseDetails.capturedCases;
            this.resolvedCases = this.caseDetails.resolvedCases;
            this.releasedCases = this.caseDetails.releasedCases;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>
